import axios from 'axios'
import React, { useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { API_URL } from '../../constants'

const Pricing = (currentUser) => {

  const [showQR, setShowQR] = useState(false)
  const [displayMsg, setDisplayMsg] =  useState(null)



  const onboardPaidUsers = async () =>{
    const token = localStorage.getItem("token")
    try {
      const response = await axios.post(`${API_URL}/onboardPaidUsers`,
        {
          data:{
            'changeUserType': 'premium'
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
  
      if (response.status == 202){

        localStorage.setItem("token", response.data.access_token)
        setDisplayMsg(response.data.message)
      }
      else if (response.status == 200) {
        setDisplayMsg(response.data.message)
      }

    } catch (error) {
      console.log(error)
      console.log(error.response.status)
      if (error.response.status == 401){
        setDisplayMsg("Please register or login (if already registered)...")
      }
      
    }

  }

  return (
    <div>
        <Alert style={{padding:'2rem'}}>
            {currentUser == null ? <h3>One month subscription starts only @ 55/- per month !!!</h3>
            :
            <>
            <Button size='lg' style={{margin:'2.5rem'}} onClick={onboardPaidUsers}> Subscribe Now </Button>
            {showQR ? <img src="/GooglePay_QR.png" width="500" height="500"></img>: null}
            
            {
              displayMsg ? <Alert variant='danger'>{displayMsg}</Alert>: null
            }
            </>
            }
            
            
        </Alert>
    </div>
  )
}

export default Pricing